import "./box.css";

export const Box = () => {
  return (
    <div className="box">
      <div className="main">
        <div className="overlap-group">
          <div className="v-banner">
            <div className="bg" />
          </div>
          <div className="overlap-group-flex">
            <p className="we-utilize-AI-to">
              We utilize AI to <br />
              provide Advanced
              <br />
              Anti-Money Laundering <br />
              technology
            </p>
            <div className="txt">
              <img className="vector" alt="Vector" src="/Vector.png" />
              <p className="onclev-is-an-IT">
                Onclev is an IT company that uses
                <br />
                AI and Big data analysis to prevent
                <br />
                financial crime damage.
              </p>
              <img className="img" alt="Vector" src="/Vector.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Box;