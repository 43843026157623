import "./company.css";
import TxtBottomGroup from "./TxtBottomGroup.js";
import Header from '../Header.js';
import Footer from '../Footer.js';
import { useState, useEffect } from "react";
import MobileHeader from "../mobile/header/MobileHeader.js";
import MobileFooter from "../mobile/footer/MobileFooter.js";
import CompanyLine from "../mobile/icon/company_line.js";
import MTxtBottomGroup from "./MTxtBottomGroup.js";


export const Company = () => {

    const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
    });

    useEffect(() => {
    function handleResize() {
        setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
        });
    }

    window.addEventListener('resize', handleResize);
    // 컴포넌트가 언마운트될 때 이벤트 리스너를 정리
    return () => window.removeEventListener('resize', handleResize);
    }, [setWindowSize]); // 빈 의존성 배열은 마운트될 때만 이펙트를 실행하라는 의미

    return (

        <div>
            {
                windowSize.width >= 720 ? 
                <>
                <div className="company">
                    <Header />
                    <div className="company_overlap_group">
                        <div className="company_title">Company</div>
                        <div className="company_txt_top_group">
                            <div className="company_txt_top_group_title">회사 소개</div>
                            <p className="company_txt_top_group_top">
                                AI와 블록체인 기술을 활용한
                                <br/>
                                금융정보 분석 서비스 개발 기업
                            </p>
                            <p className="company_txt_top_group_middle">
                                온클레브는 금융범죄와 자금세탁을 막기 위한 AI와 블록체인 기술을 활용한 레크테크 기업입니다.
                                <br/>
                                우리는 가상자산 범죄를 방지하는 솔루션을 개발하고 있으며, 청년 딥테크 스타트업으로서
                                <br/>
                                인공지능과 블록체인 전문가들이 모여 노력하고 있습니다.
                                <br/>
                                <br/>
                                최근 3년간 R&D 성과로 기술 보증 기금과 창업진흥원에서 우수 기업으로 인정받았으며,
                                <br/>
                                서울 경찰청과의 협력을 통해 현장 수사에 필요한 솔루션을 개발 중입니다.
                            </p>
                            <div className="company_txt_top_group_title company_txt_top_group_title2">회사 이념</div>
                            <p className="company_txt_top_group_bottom">
                                더 나은 내일, 더 정의로운 사회를 위해<br/>첨단 기술의 선두에 서다.
                            </p>    
                        </div>
                        <div className="company_pointbox_group">
                            <div className="company_pointbox_group_frame">
                                <div className="company_pointbox_group_txt">
                                    <div className="company_pointbox_group_title">Solution</div>
                                    <p className="company_pointbox_group_content">
                                        온클레브는 금융범죄 수사와 가상자산을 통한 범죄
                                        <br/>
                                        자금 세탁 추적 AI 기술 개발로 다양한 기술과 접근
                                        <br/>
                                        방식 측면에서 활용할 수 있는 솔루션을 제안합니다.
                                    </p>
                                </div>
                                <img className="company_img" src="/company_lock.png" alt="lock"/>
                            </div>
                            <div className="company_pointbox_group_frame">
                                <div className="company_pointbox_group_txt">
                                    <div className="company_pointbox_group_title">Innvation</div>
                                    <p className="company_pointbox_group_content">
                                        레크테크 기업으로 기술의 최전선에서 AI와 블록체인
                                        <br/>
                                        기술을 융합하며, 금융정보를 철저히 분석하고
                                        <br/>
                                        혁신적인 솔루션을 개발합니다.
                                    </p>
                                </div>
                                <img className="company_img" src="/company_chain.png" alt="chain"/>
                            </div>
                            <div className="company_pointbox_group_frame">
                                <div className="company_pointbox_group_txt">
                                    <div className="company_pointbox_group_title">Social justice</div>
                                    <p className="company_pointbox_group_content">
                                        온클레브는 모든 개인이 동등한 기회와 존엄성을
                                        <br/>
                                        가지며, 사회의 각 구성원이 공평하게 대우받고 공정
                                        <br/>
                                        하게 살 수 있는 상태를 지향합니다.
                                    </p>
                                </div>
                                <img className="company_img" src="/company_scale.png" alt="scale"/>
                            </div>
                        </div>
                        <div className="company_txt_top_group_title company_txt_top_group_title3">연혁</div>
                        
                        <div className="company_txt_bottom_group">
                            <TxtBottomGroup
                                year = {"2023"}
                                month = {"11"}
                                month_content1 = {"입스(TIPS) 창업기업 선정"}
                                month_content2 = {"‘크립톤’ Seed 투자 유치"}
                                month_content3 = {"서울 핀테크랩 지원기업 선정"}
                                month2 = {"03"}
                                month2_content1 = {"소설벤처기업 인증"}
                                month2_content2 = {""}
                                month2_content3 = {""}
                                padding_top = {"0px"}
                            />
                            <TxtBottomGroup
                                year = {"2022"}
                                month = {"12"}
                                month_content1 = {"기본벤처캠프 스타기업상 수상"}
                                month_content2 = {""}
                                month_content3 = {""}
                                month2 = {"11"}
                                month2_content1 = {
                                    <>
                                        글로벌 가상자산 거래소
                                        <br/>
                                        ‘XT.com’ AML MOU
                                    </>
                                }
                                month2_content2 = {
                                    <>
                                        글로벌 가상자산 거래소
                                        <br/>
                                        ‘Leo21’ AML MOU
                                </>
                                }
                                month2_contont3 = {""}
                                padding_top = {"100px"}
                            />
                            <TxtBottomGroup
                                year = {"2021"}
                                month = {"09"}
                                month_content1 = {"서울경찰청 암호화폐 범죄 수사전문"}
                                month_content2 = {""}
                                month_content3 = {""}
                                month2 = {""}
                                month2_content1 = {""}
                                month2_content2 = {""}
                                month2_content3 = {""}
                                padding_top = {"120px"}
                            />
                            <TxtBottomGroup
                                year = {"2020"}
                                month = {"06"}
                                month_content1 = {"예비창업패키지 최우수 기업 선정"}
                                month_content2 = {""}
                                month_content3 = {""}
                                month2 = {""}
                                month2_content1 = {""}
                                month2_content2 = {""}
                                month2_content3 = {""}
                                padding_top = {"120px"}
                            />
                        </div>
                        

                        <div className="company_bg" />  
                    </div>
                    <Footer />
                </div>
                </>
                :
                <div style={{ backgroundColor:"black"}}>
                <MobileHeader />
                <div className="mobile_company_overlap_group">
                        <div className="mobile_company_title_group">
                            <div className="mobile_company_title">Company</div>
                            <CompanyLine />
                        </div>
                        <div className="mobile_company_txt_top_group">
                            <div className="mobile_company_txt_top_group_title">회사 소개</div>
                            <p className="mobile_company_txt_top_group_top">
                                AI와 블록체인 기술을 활용한
                                <br/>
                                금융정보 분석 서비스 개발 기업
                            </p>
                            <p className="mobile_company_txt_top_group_middle">
                                온클레브는 금융범죄와 자금세탁을 막기 위한 AI와
                                <br/>
                                블록체인 기술을 활용한 레크테크 기업입니다.
                                <br/>
                                우리는 가상자산 범죄를 방지하는 솔루션을
                                <br/> 
                                개발하고 있으며, 청년 딥테크 스타트업으로서
                                <br/>
                                인공지능과 블록체인 전문가들이 모여
                                <br/>
                                노력하고 있습니다.
                                <br/>
                                <br/>
                                최근 3년간 R&D 성과로 기술 보증 기금과
                                <br/>
                                창업진흥원에서 우수 기업으로 인정받았으며,
                                <br/>
                                서울 경찰청과의 협력을 통해 현장 수사에
                                <br/>
                                필요한 솔루션을 개발 중입니다.
                            </p>
                            <div className="mobile_company_txt_top_group_title company_txt_top_group_title2">회사 이념</div>
                            <p className="mobile_company_txt_top_group_bottom">
                                더 나은 내일,
                                <br/> 
                                더 정의로운 사회를 위해
                                <br/>
                                첨단 기술의 선두에 서다.
                            </p>        
                        </div>
                        <div className="mobile_company_pointbox_group">
                            <div className="mobile_company_pointbox_group_frame">
                                <div className="mobile_company_pointbox_group_txt">
                                    <div className="mobile_company_pointbox_group_title">Solution</div>
                                    <p className="mobile_company_pointbox_group_content">
                                        온클레브는 금융범죄 수사와 가상자산을 통한 범죄
                                        <br/>
                                        자금 세탁 추적 AI 기술 개발로 다양한 기술과 접근
                                        <br/>
                                        방식 측면에서 활용할 수 있는 솔루션을 제안합니다.
                                    </p>
                                </div>
                                <img className="mobile_company_img" src="/company_lock.png" alt="lock"/>
                            </div>
                            <div className="mobile_company_pointbox_group_frame">
                                <div className="mobile_company_pointbox_group_txt">
                                    <div className="mobile_company_pointbox_group_title">Innvation</div>
                                    <p className="mobile_company_pointbox_group_content">
                                        레크테크 기업으로 기술의 최전선에서 AI와 블록체인
                                        <br/>
                                        기술을 융합하며, 금융정보를 철저히 분석하고
                                        <br/>
                                        혁신적인 솔루션을 개발합니다.
                                    </p>
                                </div>
                                <img className="mobile_company_img" src="/company_chain.png" alt="chain"/>
                            </div>
                            <div className="mobile_company_pointbox_group_frame">
                                <div className="mobile_company_pointbox_group_txt">
                                    <div className="mobile_company_pointbox_group_title">Social justice</div>
                                    <p className="mobile_company_pointbox_group_content">
                                        온클레브는 모든 개인이 동등한 기회와 존엄성을
                                        <br/>
                                        가지며, 사회의 각 구성원이 공평하게 대우받고 공정
                                        <br/>
                                        하게 살 수 있는 상태를 지향합니다.
                                    </p>
                                </div>
                                <img className="mobile_company_img" src="/company_scale.png" alt="scale"/>
                            </div>
                        </div>
                        <div className="mobile_company_txt_top_group_title mobile_company_txt_top_group_title3">연혁</div>
                        
                        <div className="mobile_company_txt_bottom_group">
                            <MTxtBottomGroup
                                year = {"2023"}
                                month = {"11"}
                                month_content1 = {"입스(TIPS) 창업기업 선정"}
                                month_content2 = {"‘크립톤’ Seed 투자 유치"}
                                month_content3 = {"서울 핀테크랩 지원기업 선정"}
                                month2 = {"03"}
                                month2_content1 = {"소설벤처기업 인증"}
                                month2_content2 = {""}
                                month2_content3 = {""}
                                padding_top = {"30px"}
                                svg_height = {"250px"}
                            />
                            <MTxtBottomGroup
                                year = {"2022"}
                                month = {"12"}
                                month_content1 = {"기본벤처캠프 스타기업상 수상"}
                                month_content2 = {""}
                                month_content3 = {""}
                                month2 = {"11"}
                                month2_content1 = {
                                    <>
                                        글로벌 가상자산 거래소
                                        <br/>
                                        ‘XT.com’ AML MOU
                                    </>
                                }
                                month2_content2 = {
                                    <>
                                        글로벌 가상자산 거래소
                                        <br/>
                                        ‘Leo21’ AML MOU
                                </>
                                }
                                month2_contont3 = {""}
                                padding_top = {"10px"}
                                svg_height = {"250px"}
                            />
                            <MTxtBottomGroup
                                year = {"2021"}
                                month = {"09"}
                                month_content1 = {<>서울경찰청 암호화폐<br/> 범죄 수사전문</>}
                                month_content2 = {""}
                                month_content3 = {""}
                                month2 = {""}
                                month2_content1 = {""}
                                month2_content2 = {""}
                                month2_content3 = {""}
                                padding_top = {"10px"}
                                svg_height = {"250px"}
                            />
                            <MTxtBottomGroup
                                year = {"2020"}
                                month = {"06"}
                                month_content1 = {<>예비창업패키지 최우수<br/> 기업 선정</>}
                                month_content2 = {""}
                                month_content3 = {""}
                                month2 = {""}
                                month2_content1 = {""}
                                month2_content2 = {""}
                                month2_content3 = {""}
                                padding_top = {"10px"}
                                svg_height = {"100px"}
                            />
                        </div>
                        
                    </div>
                    <div className="mobile_company_bg" /> 
                <MobileFooter />
            </div>
            }
        </div>
      );
  };

export default Company;