import "./technology.css";
import Header from '../Header.js';
import Footer from '../Footer.js';
import TechnologyBox from './TechnologyBox.js'
import { useState, useEffect } from "react";
import MobileHeader from "../mobile/header/MobileHeader.js";
import MobileFooter from "../mobile/footer/MobileFooter.js";
import TechnologyLine from "../mobile/icon/technology_line.js";
import MTechnologyBox from "./MTechnologyBox.js";

export const Technology = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    
    useEffect(() => {
    function handleResize() {
        setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
        });
    }
    
    window.addEventListener('resize', handleResize);
    // 컴포넌트가 언마운트될 때 이벤트 리스너를 정리
    return () => window.removeEventListener('resize', handleResize);
    }, [setWindowSize]); // 빈 의존성 배열은 마운트될 때만 이펙트를 실행하라는 의미

    return(
        <div>
        {
            windowSize.width >= 720 ? 
            <>
                    <div className="technology">
                    <Header />
                    <div className="technology_overlap_group">
                        <div className="technology_title">Technology</div>
                        <div className="technology_list_group">
                            <TechnologyBox 
                                titleNumber={"[01]"} 
                                title={"Investigation"} 
                                description={
                                <>
                                    가상자산 범죄 자금 세탁 추적 등 금융범죄 수사에 
                                    <br/>
                                    필요한 범죄 자금흐름 추적을 위한 AI 기술 개발
                                </>
                                }
                                Image={"./technology_img1.png"}
                                ovj={"./Technology_ovj.png"}
                                isBar={true}
                            />
                            <TechnologyBox 
                                titleNumber={"[02]"} 
                                title={"Securities"} 
                                description={
                                <>
                                    AI 패턴분석을 통하여 유가증권의 유의종목, 자전거래 등을 
                                    <br/>
                                    사전에 탐지할 수 있는 이상거래 모니터링 기술 개발
                                </>
                                }
                                Image={"./technology_img2.png"}
                                ovj={"./Technology_ovj.png"}
                                isBar={false}
                            />
                            <TechnologyBox 
                                titleNumber={"[03]"} 
                                title={"Transactions"} 
                                description={
                                <>
                                    금융 트랜잭션(거래흐름) 분석을 통하여 불법 대포통장, 
                                    <br/>
                                    사기 의심 계좌 등 금융범죄 탐지를 위한 기술 개발
                                </>
                                }
                                Image={"./technology_img3.png"}
                                ovj={"./Technology_ovj.png"}
                                isBar={false}
                            />
                            <TechnologyBox 
                                titleNumber={"[04]"} 
                                title={"Virtual Assests FDS"} 
                                description={
                                <>
                                    가상자산 거래소의 내부 거래에 있어 자전 거래, 
                                    <br/>
                                    봇 거래 등 이상거래를 탐지하는 솔루션
                                </>
                                }
                                Image={"./technology_img4.png"}
                                ovj={"./Technology_ovj.png"}
                                isBar={false}
                            />
                            <TechnologyBox 
                                titleNumber={"[05]"} 
                                title={"Wallet"} 
                                description={
                                <>
                                    AML 기능 탑재 코인 지갑으로써 가상자산 거래소의 입출금에 있어 
                                    <br/>
                                    자금 출처 증빙 및 범죄 자금 관련 소명에 유리한 지갑
                                </>
                                }
                                Image={"./technology_img5.png"}
                                ovj={"./Technology_ovj.png"}
                                isBar={false}
                            />
                        </div>
                        <div className="technology_bg" /> 
                    </div>
                    <Footer />
                </div>
            </>
            :
            <div style={{backgroundColor:"black"}}>
            <MobileHeader />
            <div className="mobile_technology_overlap_group">
                <div className="mobile_technology_title_group">
                    <div className="mobile_technology_title">Technology</div>
                    <TechnologyLine />
                </div>
                <div className="mobile_technology_list_group">
                    <MTechnologyBox 
                        titleNumber={"[01]"} 
                        title={"Investigation"} 
                        description={
                        <>
                            가상자산 범죄 자금 세탁 추적 등
                            <br/>
                            금융범죄 수사에 필요한 범죄
                            <br/>
                            자금흐름 추적을 위한 AI 기술 개발
                        </>
                        }
                        Image={"./technology_img1.png"}
                    />
                    <MTechnologyBox 
                        titleNumber={"[02]"} 
                        title={"Securities"} 
                        description={
                        <>
                            AI 패턴분석을 통하여 유가증권의 유의종목,
                            <br/>
                            자전거래 등을 사전에 탐지할 수 있는
                            <br/>
                            이상거래 모니터링 기술 개발
                        </>
                        }
                        Image={"./technology_img2.png"}
                    />
                    <MTechnologyBox 
                        titleNumber={"[03]"} 
                        title={"Transactions"} 
                        description={
                        <>
                            금융 트랜잭션(거래흐름) 분석을 통하여 불법
                            <br/>
                            대포통장, 사기 의심 계좌 등 금융범죄 탐지를 위한
                            <br/>
                            기술 개발
                        </>
                        }
                        Image={"./technology_img3.png"}
                    />
                    <MTechnologyBox 
                        titleNumber={"[04]"} 
                        title={"Virtual Assests FDS"} 
                        description={
                        <>
                            가상자산 거래소의 내부 거래에 있어 자전 거래, 
                            <br/>
                            봇 거래 등 이상거래를 탐지하는 솔루션
                        </>
                        }
                        Image={"./technology_img4.png"}
                    />
                    <MTechnologyBox 
                        titleNumber={"[05]"} 
                        title={"Wallet"} 
                        description={
                        <>
                            AML 기능 탑재 코인 지갑으로써 가상자산 거래소의
                            <br/>
                            입출금에 있어 자금 출처 증빙 및
                            <br/>
                             범죄 자금 관련 소명에 유리한 지갑
                        </>
                        }
                        Image={"./technology_img5.png"}
                    />
                </div>
            </div>
            <div className="mobile_technology_bg" /> 
            <MobileFooter />
        </div>
        }
    </div>
    );
};

export default Technology;