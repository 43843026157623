import './footer.css';
import { useState, useEffect } from 'react';

export const Footer = () => {

  const [isVisible, setIsVisible] = useState(false);

  // 사용자가 페이지를 어느 정도 스크롤 다운했는지 감지
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 500) { // 300픽셀 이상 스크롤됐을 때 버튼 보이기
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [setIsVisible]);

  // 스크롤을 페이지 최상단으로 이동시키는 함수
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // 부드러운 스크롤 효과
    });
  };

    return (
      <div className="footer">
        <div className="footer_overlap_group">
          <div className="footer_group">
            <div className="footer_information_group">
              <div className="footer_information_title">INFORMATION </div>
              <div className="footer_information_txt_frame">
                <div className="footer_information_txt_group">
                  <div className="footer_information_txt">회사명</div>
                  <div className="footer_information_txt2">(주)온클레브</div>
                </div>
                <div className="footer_information_txt_group">
                  <div className="footer_information_txt">사업자등록번호</div>
                  <div className="footer_information_txt2">726-86-01973</div>
                </div>
              </div>
            </div>
            <div className="footer_information_group">
              <div className="footer_information_title">ADDRESS </div>
              <div className="footer_information_txt_group">
                <div className="footer_information_txt3">서울 마포구 잔다리로3안길 46 4층(서교동)</div>
              </div>
            </div>
            <div className="footer_information_group">
              <div className="footer_information_title">CONTACT </div>
              <div className="footer_information_txt_group">
                <div className="footer_information_txt">이메일</div>
                <div className="footer_information_txt2">contact@onclev.com</div>
              </div>
            </div>
            <div className="copyright">
              <div className="copyright_txt">COPYRIGHT 2024 ONCLEV INC. ALL RIGHTS RESERVED.</div>
            </div>
          </div>
          <div className="footer_bg" />
        </div>
        
        <div>
        {isVisible && (
          <img className="footer_top_btn" src="footer_top.png" alt="top" onClick={scrollToTop} >
          </img>
        )}
    </div>
      </div>
    );
  };
  
  export default Footer;