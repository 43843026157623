export const TechnologyLine = () => {

    return(
        <svg width="165" height="26" viewBox="0 0 165 26" fill="none" xmlns="http://www.w3.org/2000/svg" style={{paddingTop: "50px"}}>
            <path d="M90.9467 10C84.8375 10 79.8672 13.3636 79.8672 17.502C79.8672 21.3913 75.1889 24.5573 69.4418 24.5573H0.5V25H69.4418C75.551 25 80.5272 21.6364 80.5272 17.498C80.5272 13.6087 85.2055 10.4427 90.9526 10.4427H163V10L90.9467 10Z" stroke="url(#paint0_linear_52_543)" strokeMiterlimit="10"/>
            <path d="M156 19.7012L164 10.2012L156 0.701172" stroke="#DDDDDD" strokeMiterlimit="10"/>
            <defs>
            <linearGradient id="paint0_linear_52_543" x1="-5" y1="17.502" x2="97.1688" y2="17.502" gradientUnits="userSpaceOnUse">
            <stop stopColor="#DDDDDD" stopOpacity="0"/>
            <stop offset="1" stopColor="#DDDDDD"/>
            </linearGradient>
            </defs>
        </svg>


    );
};

export default TechnologyLine;