import './box2.css';

export const Box2 = () => {
    return (
        <div className="box2">
            <div className="box2_vetor">
                <div className='box2_bg' />
                <div className ="box2_overlap_group">
                    <p className="AI">
                        AI를 통한 빅데이터 분석으로
                        <br/>
                        금융범죄 피해 예방을 위한 기술을 혁신합니다.
                    </p>
                    <p className="AML-CFT">
                        온클레브는 고도화되고 있는 금융 범죄 대응 수단으로
                        <br />
                        자금세탁방지(AML)과 테러자금조달방지(CFT)를 위한 기술 개발에 힘쓰고 있습니다.
                    </p>
                </div>
                <div className ="box2_point_group">
                    <div className="point-text">
                        <div className="point-text_text">onclev point 1</div>
                        <div className="point-text_text_2">인공지능</div>
                    </div>
                    <div className="point-text-2">
                        <div className="point-text_text">onclev point 2</div>
                        <div className="point-text_text_2">블록체인</div>
                    </div>
                </div>
                <div className ="box2_point_group2">
                    <div className="point-text-3">
                        <div className="point-text_text">onclev point 3</div>
                        <div className="point-text_text_3">
                            금융범죄
                            <br />
                            수사자문
                        </div>
                    </div>
                    <div className="point-text-4">
                        <div className="point-text_text">onclev point 4</div>
                        <div className="point-text_text_3">
                            산학협력
                            <br />
                            기술개발
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Box2;