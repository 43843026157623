import './mobilemodal.css'
import { Link } from 'react-router-dom';


const MobileModal = ({ isOpen, close }) => {
  if (!isOpen) return null;

  return (
    <div className='mobile_overlay'>
      <div className='mobile_modal'>
        <span className="mobile_closeBtn" onClick={close}>&times;</span>
        <div className="mobile_modal_navbar">
            <Link to='/' className="mobile_modal_header_text_wrapper">HOME</Link>
            <Link to='/company' className="mobile_modal_header_text_wrapper">Company</Link>
            <Link to='/technology' className="mobile_modal_header_text_wrapper">Technology</Link>
            <Link to='/partners' className="mobile_modal_header_text_wrapper">Partners</Link>
            <Link to='/news' className="mobile_modal_header_text_wrapper">News</Link>
            <Link to='/contact' className="mobile_modal_header_text_wrapper">Contact</Link>
        </div>
      </div>
    </div>
  );
};

export default MobileModal;