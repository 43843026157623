import Header from '../Header.js'
import Box from './Box.js';
import Box2 from './Box2.js';
import Box3 from './Box3.js';
import Ecosystem from './ecosystem';
import Box5 from './Box5.js';
import Footer from '../Footer.js';
import MobileFooter from '../mobile/footer/MobileFooter.js';
import MobileHeader from '../mobile/header/MobileHeader.js';
import MobileBox from '../mobile/main/MobileBox.js';
import MobileBox2 from '../mobile/main/MobileBox2.js';
import MobileBox3 from '../mobile/main/MobileBox3.js';
import MobileEcosystem from '../mobile/main/MobileEcosystem1.js'
import MobileBox5 from '../mobile/main/MobileBox5.js';
import { useState, useEffect } from "react";

function Main() {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener('resize', handleResize);
    // 컴포넌트가 언마운트될 때 이벤트 리스너를 정리
    return () => window.removeEventListener('resize', handleResize);
  }, [setWindowSize]); // 빈 의존성 배열은 마운트될 때만 이펙트를 실행하라는 의미
  
    return (
      <div>
        {
          windowSize.width >= 720 ? <>
          <Header />
          <Box />
          <Box2 />
          <Box3 />
          <Ecosystem />
          <Box5 />
          <Footer />
          </> 
          : 
          <>
          <MobileHeader />
          <MobileBox />
          <MobileBox2 />
          <MobileBox3 />
          <MobileEcosystem />
          <MobileBox5 />
          <MobileFooter />
          
          </>
        }
        
      </div>
    );
  }
  
  export default Main;