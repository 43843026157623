import './mobilebox4.css';
import React, { useState, useEffect, useRef } from 'react';

export const MobileBox4 = ({
  title,
  description,
  beforeImage,
  afterImage,
  isBar
}) => {
  const componentRef = useRef(null);  // 컴포넌트 참조 생성
  const [isVisible,setIsVisible] = useState(false);

  useEffect(() => {
    if(!componentRef.current) return;

    const handleScroll = () => {
      
      const rect = componentRef.current.getBoundingClientRect();

      const max = (window.innerHeight/2);
      const min = max - rect.height;
  
      setIsVisible(rect.top >= min && rect.top <= max);
    };

    // 스크롤 이벤트 리스너 등록
    window.addEventListener('scroll', handleScroll);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [componentRef]);

  return(
  <div className="mobile_box4_hover" ref={componentRef}> 
      <div className="mobile_box4_img_hover" style={{backgroundImage: isVisible ? `url("${afterImage}")` : `url("${beforeImage}")`, height: isBar ? '190px':'41px'}}/>
      <div className="mobile_box4_txt_hover" >
        <CustomSVG isVisible={isVisible} />
        <div className="mobile_box4_txt_hover_title" style={{color: isVisible ? '#111111':'#dddddd' }}>{title}</div>
        <p className="mobile_box4_txt_hover_content" style={{color: isVisible ? '#777777':'#dddddd' }}>
          {description}
        </p>
      </div>
    </div>
  );
};

const CustomSVG = ({ isVisible }) => {
  return (
          <svg className="mobile_box4_txt_hover_svg" 
          style={{ position: "absolute",
                  top : "-7px",
                  left: "39px",
                  zIndex: "900",
                  opacity: isVisible ?  1 : 0
          }} width="259" height="149" viewBox="0 0 259 149" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 23.5957L17.6972 2H257V119.234L236.818 147" stroke="#16D7CD" strokeWidth="3" strokeLinecap="round"/>
            <path d="M2 23.5957L17.6972 2H257V119.234L236.818 147" stroke="url(#paint0_linear_312_99)" strokeWidth="3" strokeLinecap="round"/>
            <path d="M208.846 146.998H2V57.6992" stroke="#16D7CD" strokeWidth="3" strokeLinecap="round"/>
            <path d="M208.846 146.998H2V57.6992" stroke="url(#paint1_linear_312_99)" strokeWidth="3" strokeLinecap="round"/>
            <defs>
            <linearGradient id="paint0_linear_312_99" x1="106.375" y1="1.99998" x2="192.896" y2="153.46" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0087CD" stopOpacity="0"/>
            <stop offset="1" stopColor="#0087CD"/>
            </linearGradient>
            <linearGradient id="paint1_linear_312_99" x1="86.6647" y1="57.6992" x2="131.826" y2="161.828" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0087CD" stopOpacity="0"/>
            <stop offset="1" stopColor="#0087CD"/>
            </linearGradient>
            </defs>
          </svg>

  );
}
 
export default MobileBox4;