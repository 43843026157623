import './dpartnersbox.css'

export const DPartnersBox = ({
    img,
    alt,
    name,
    backgroundcolor,
    padding
}) => {

    return(
        <div className="partnerbox_logo_frame" > 
            <img className="partnerbox_logo_img" src={img} alt={alt} style={{backgroundColor:backgroundcolor, padding:padding}} />
            <div className="partnerbox_logo_txt">{name}</div>
        </div>
    );
};

export default DPartnersBox;