import "./mobilebox.css";

export const MobileBox = () => {
  return (
    <div className="mobile_box">
      <div className="mobile_main">
        <div className="mobile_overlap-group">
          <div className="mobile_v-banner">
            <div className="mobile_bg" />
          </div>
          <div className="mobile_overlap-group-flex">
            <p className="mobile_we-utilize-AI-to">
              We utilize AI to <br />
              provide Advanced
              <br />
              Anti-Money Laundering <br />
              technology
            </p>
            <div className="mobile_txt">
              <img className="mobile_vector" alt="Vector" src="/Vector.png" />
              <p className="mobile_onclev-is-an-IT">
                Onclev is an IT company that uses
                <br />
                AI and Big data analysis to prevent
                <br />
                financial crime damage.
              </p>
              <img className="mobile_img" alt="Vector" src="/Vector.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileBox;