import './mobilefooter.css';
import { useState, useEffect } from 'react';

export const MobileFooter = () => {

  const [isVisible, setIsVisible] = useState(false);

  // 사용자가 페이지를 어느 정도 스크롤 다운했는지 감지
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 100) { // 300픽셀 이상 스크롤됐을 때 버튼 보이기
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [setIsVisible]);

  // 스크롤을 페이지 최상단으로 이동시키는 함수
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // 부드러운 스크롤 효과
    });
  };

    return (
      <div className="mobile_footer">
        <div className="mobile_footer_overlap_group">
          <div className="mobile_footer_group">
            <div className="mobile_footer_information_group">
              <div className="mobile_footer_information_title">INFORMATION </div>
              <div className="mobile_footer_information_txt_frame">
                <div className="mobile_footer_information_txt_group">
                  <div className="mobile_footer_information_txt">회사명</div>
                  <div className="mobile_footer_information_txt2">(주)온클레브</div>
                </div>
                <div className="mobile_footer_information_txt_group">
                  <div className="mobile_footer_information_txt">사업자등록번호</div>
                  <div className="mobile_footer_information_txt2">726-86-01973</div>
                </div>
              </div>
            </div>
            <div className="mobile_footer_information_group">
              <div className="mobile_footer_information_title">ADDRESS </div>
              <div className="mobile_footer_information_txt_group">
                <div className="mobile_footer_information_txt3">서울 마포구 잔다리로3안길 46 4층(서교동)</div>
              </div>
            </div>
            <div className="mobile_footer_information_group">
              <div className="mobile_footer_information_title">CONTACT </div>
              <div className="mobile_footer_information_txt_group">
                <div className="mobile_footer_information_txt">이메일</div>
                <div className="mobile_footer_information_txt2">contact@onclev</div>
              </div>
            </div>
            <div className="mobile_footer_copyright">
              <div className="mobile_footer_copyright_txt">© 2024 ONCLEV INC. ALL RIGHTS RESERVED.</div>
            </div>
            <svg className="mobile_footer_svg" width="375" height="46" viewBox="0 0 375 46" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_52_236)">
              <path d="M35.8797 71.9194H21.9538C15.2912 71.9194 9.93928 69.9032 5.89803 65.871C1.96601 61.8387 0 56.4442 0 49.6875V10.7816C0 4.02485 1.96601 -1.36966 5.89803 -5.40192C9.93928 -9.43418 15.2912 -11.4503 21.9538 -11.4503H35.8797C42.5423 -11.4503 47.8396 -9.43418 51.7716 -5.40192C55.8129 -1.36966 57.8335 4.02485 57.8335 10.7816V49.6875C57.8335 56.4442 55.8129 61.8387 51.7716 65.871C47.8396 69.9032 42.5423 71.9194 35.8797 71.9194ZM21.9538 67.3422H35.8797C41.3408 67.3422 45.5459 65.762 48.4949 62.6016C51.444 59.4412 52.9185 55.0819 52.9185 49.524V10.9451C52.9185 5.3871 51.444 1.0279 48.4949 -2.13252C45.5459 -5.29294 41.3408 -6.87315 35.8797 -6.87315H21.9538C16.4927 -6.87315 12.2876 -5.29294 9.33855 -2.13252C6.38954 1.0279 4.91503 5.3871 4.91503 10.9451V49.524C4.91503 55.0819 6.38954 59.4412 9.33855 62.6016C12.2876 65.762 16.4927 67.3422 21.9538 67.3422Z" fill="white"/>
              <path d="M82.7632 71.9194H80.4695C79.5957 71.9194 79.1588 71.4289 79.1588 70.4481V-9.97908C79.1588 -10.9599 79.5957 -11.4503 80.4695 -11.4503H82.7632C83.637 -11.4503 84.0739 -10.9599 84.0739 -9.97908V-2.94987H84.7292C87.7874 -8.61683 93.5762 -11.4503 102.096 -11.4503H114.219C120.882 -11.4503 126.179 -9.43418 130.111 -5.40192C134.153 -1.36966 136.173 4.02485 136.173 10.7816V70.4481C136.173 71.4289 135.736 71.9194 134.862 71.9194H132.569C131.695 71.9194 131.258 71.4289 131.258 70.4481V10.9451C131.258 5.3871 129.784 1.0279 126.835 -2.13252C123.886 -5.29294 119.68 -6.87315 114.219 -6.87315H102.423C96.9622 -6.87315 92.5386 -5.40192 89.1527 -2.45946C85.7668 0.482998 84.0739 4.67873 84.0739 10.1277V70.4481C84.0739 71.4289 83.637 71.9194 82.7632 71.9194Z" fill="white"/>
              <path d="M203.334 68.65V70.6116C203.334 71.4834 202.842 71.9194 201.859 71.9194H178.595C171.932 71.9194 166.58 69.9032 162.539 65.871C158.607 61.8387 156.641 56.4442 156.641 49.6875V10.7816C156.641 4.02485 158.607 -1.36966 162.539 -5.40192C166.58 -9.43418 171.932 -11.4503 178.595 -11.4503H201.859C202.842 -11.4503 203.334 -11.0144 203.334 -10.1426V-8.18091C203.334 -7.30907 202.842 -6.87315 201.859 -6.87315H178.595C173.134 -6.87315 168.928 -5.29294 165.979 -2.13252C163.03 1.0279 161.556 5.3871 161.556 10.9451V49.524C161.556 55.0819 163.03 59.4412 165.979 62.6016C168.928 65.762 173.134 67.3422 178.595 67.3422H201.859C202.842 67.3422 203.334 67.7781 203.334 68.65Z" fill="white"/>
              <path d="M223.264 71.9194H220.97C220.096 71.9194 219.66 71.4289 219.66 70.4481V-41.5288C219.66 -42.5096 220.096 -43 220.97 -43H223.264C224.138 -43 224.575 -42.5096 224.575 -41.5288V70.4481C224.575 71.4289 224.138 71.9194 223.264 71.9194Z" fill="white"/>
              <path d="M302.264 71.9194H268.678C262.015 71.9194 256.663 69.9032 252.622 65.871C248.69 61.8387 246.724 56.4442 246.724 49.6875V10.7816C246.724 4.02485 248.69 -1.36966 252.622 -5.40192C256.663 -9.43418 262.015 -11.4503 268.678 -11.4503H283.095C289.758 -11.4503 295.055 -9.43418 298.987 -5.40192C303.029 -1.36966 305.049 4.02485 305.049 10.7816V31.5423C305.049 32.6321 304.558 33.177 303.575 33.177H253.114C252.131 33.177 251.639 33.6129 251.639 34.4847V49.524C251.639 55.0819 253.114 59.4412 256.063 62.6016C259.012 65.762 263.217 67.3422 268.678 67.3422H302.264C303.247 67.3422 303.738 67.7781 303.738 68.65V70.6116C303.738 71.4834 303.247 71.9194 302.264 71.9194ZM253.114 28.5998H298.66C299.643 28.5998 300.134 28.1639 300.134 27.2921V10.9451C300.134 5.3871 298.66 1.0279 295.711 -2.13252C292.762 -5.29294 288.556 -6.87315 283.095 -6.87315H268.678C263.217 -6.87315 259.012 -5.29294 256.063 -2.13252C253.114 1.0279 251.639 5.3871 251.639 10.9451V27.2921C251.639 28.1639 252.131 28.5998 253.114 28.5998Z" fill="white"/>
              <path d="M316.88 -11.4503H319.501C320.266 -11.4503 320.812 -10.9599 321.139 -9.97908L345.059 65.2171H345.715L369.634 -9.97908C369.962 -10.9599 370.508 -11.4503 371.273 -11.4503H373.894C374.877 -11.4503 375.205 -10.9599 374.877 -9.97908L348.991 70.4481C348.664 71.4289 348.063 71.9194 347.189 71.9194H343.585C342.711 71.9194 342.11 71.4289 341.783 70.4481L315.897 -9.97908C315.569 -10.9599 315.897 -11.4503 316.88 -11.4503Z" fill="white"/>
              </g>
              <defs>
              <clipPath id="clip0_52_236">
              <rect width="375" height="46" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </div>
          
          <div className="mobile_footer_bg" />
        </div>
        
        <div>
        {isVisible && (
          
          <img className="mobile_footer_top_btn" src="footer_top.png" alt="top" onClick={scrollToTop} >
          </img>
        )}
    </div>
      </div>
    );
  };
  
  export default MobileFooter;