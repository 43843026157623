import './technologybox.css';
import React, { useState, useEffect, useRef } from 'react';

export const TechnologyBox = ({
    titleNumber,
    title,
    description,
    Image,
    ovj,
    isBar
  }) => {
    
    const componentRef = useRef(null);  // 컴포넌트 참조 생성
    const [isVisible,setIsVisible] = useState(false);

    useEffect(() => {
        if(!componentRef.current) return;

        const handleScroll = () => {
        
        const rect = componentRef.current.getBoundingClientRect();

        const max = (window.innerHeight/2);
        const min = max - rect.height;
    
        setIsVisible(rect.top >= min && rect.top <= max);
        };

        // 스크롤 이벤트 리스너 등록
        window.addEventListener('scroll', handleScroll);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, [componentRef]);


    return(
        <div className="technology_list1 technology_list" ref={componentRef}>
                <div className="technology_list_txt_group">
                    <div className="technology_list_txt_top" style={{color: isVisible ? "#16d7cd" : "#444444"}}>{titleNumber}</div>
                    <div className="technology_list_txt_bottom" style={{color: isVisible ? "#16d7cd" : "#444444"}}>{title}</div>
                </div>
                <p className="technology_list_txt_group2" style={{color: isVisible ? "#ffffff" : "#444444"}}>
                    {description}
                </p>
                <img className="technology_list_ovj" src={ovj} alt="ovj" style={{opacity: (isVisible && isBar) ? 1 : 0}}/>
                <img className="technology_list_img" src={Image} alt="Investigation"  style={{opacity: isVisible ? 1 : 0}}/>
            </div>
    );
  };


  export default TechnologyBox;