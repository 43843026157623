export const Box3MiniArrow = () => {

    return(
        <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg" filter="contrast(150%) brightness(120%)" style={{paddingTop : "10px"}}>
            <path id="Vector 11" d="M16.3911 0.5L25 9M25 9L16.3911 17.5M25 9H0" stroke="white"/>
        </svg>

    );
};

export default Box3MiniArrow;