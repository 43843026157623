export const Box3Arrow = () => {

    return(
        <svg width="117" height="15" viewBox="0 0 117 15" fill="none" xmlns="http://www.w3.org/2000/svg" filter="contrast(150%) brightness(120%)" style={{paddingTop : "65px", position: "relative"}}>
            <path d="M70.2112 5.00781C68.573 5.00781 67.2402 7.07079 67.2402 9.6089C67.2402 11.9943 65.9857 13.9361 64.4446 13.9361H1V14.2076H64.4446C66.0828 14.2076 67.4172 12.1446 67.4172 9.60648C67.4172 7.22109 68.6717 5.27932 70.2128 5.27932H115.745V5.00781H70.2112Z" stroke="url(#paint0_linear_52_143)" strokeMiterlimit="10"/>
            <path d="M113.006 9.31493L116 5.15747L113.006 1" stroke="white" strokeMiterlimit="10"/>
            <defs>
            <linearGradient id="paint0_linear_52_143" x1="0.843382" y1="9.6089" x2="115.9" y2="9.6089" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0"/>
            <stop offset="1" stopColor="white"/>
            </linearGradient>
            </defs>
          </svg>
    );
};

export default Box3Arrow;