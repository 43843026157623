import "./news.css";
import Header from '../Header.js';
import Footer from '../Footer.js';
import _ from 'lodash'
import { useMemo, useState,useEffect } from "react";
import MobileHeader from "../mobile/header/MobileHeader.js";
import MobileFooter from "../mobile/footer/MobileFooter.js";
import Newsline from "../mobile/icon/news_line.js"


export const News = () => {
    const [newsItems,setNewsItems] = useState([
        {
            title : "\"블록체인은 투명한 세상...불법 자금 결국 덜미 잡혀\" 이재원 온클레브 대표 인터뷰",
            src : './News_img/opinionnews.jpg' , 
            date : "2024.05.17",
            a : "https://www.opinionnews.co.kr/news/articleView.html?idxno=98509",
        },
        {
            title : "가상자산 수사 ‘온클레브’, 크립톤서 시드 투자 유치+팁스 선정",
            src : './News_img/news_onclev.png' , 
            date : "2023.12.07",
            a : "https://wowtale.net/2023/12/07/67834/",
        },
        {
            title : "AI 암호화폐 수사 프로그램 '온클레브', 'Good-Service Award'",
            src : './News_img/news_onclev.png' , 
            date : "2023.08.11",
            a : "https://news.mt.co.kr/mtview.php?no=2023081117002017571",
        },
        {
            title : "크립톤, 온클레브에 시드 투자 진행",
            src : './News_img/news_onclev.png' , 
            date : "2023.12.07",
            a : "https://www.venturesquare.net/905746",
        },
        {
            title : "온클레브, 제5회 국제치안산업대전서 AI 가상자산 수사 소프트웨어 'ABC' 소개",
            src : './News_img/fetv.jpg' , 
            date : "2023.10.21",
            a : "https://www.fetv.co.kr/news/article.html?no=150295",
        },
        {
            title : "온클레브, '2023 국제치안산업대전'에서 AI 가상자사 수사 프로그램 선봬",
            src : './News_img/news_onclev.png' , 
            date : "2023.10.13",
            a : "https://www.asiaa.co.kr/news/articleView.html?idxno=143039",
        },
        // {
        //     title : "언론보도 내용이 들어갈자리입니다.7",
        //     src : './News_img/news_onclev.png' , 
        //     date : "2023.10.21",
        //     a : "javascript:void(0);",
        // },
        // {
        //     title : "언론보도 내용이 들어갈자리입니다.8",
        //     src : './News_img/news_onclev.png' , 
        //     date : "2024.01.01",
        //     a : "javascript:void(0);",
        // },

    ]);
  

    const [search,setSearch] = useState('');

    const searchedItems = useMemo(()=> {
        return newsItems.filter((item)=> item.title.includes(search))
    },[newsItems,search])

    const totalCount = useMemo(()=>{
        return searchedItems.length;
    },[searchedItems])

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    
    useEffect(() => {
    function handleResize() {
        setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
        });
    }
    
    window.addEventListener('resize', handleResize);
    // 컴포넌트가 언마운트될 때 이벤트 리스너를 정리
    return () => window.removeEventListener('resize', handleResize);
    }, [setWindowSize]); // 빈 의존성 배열은 마운트될 때만 이펙트를 실행하라는 의미

    return(
        <div>
        {
            windowSize.width >= 720 ? 
            <>
            <div className="news">
                <Header />
                <div className="news_overlap_group">
                    <div className="news_title">News</div>
                    <img className="news_arrow_img" src="news_arrow_img.png" alt="arrow" />
                    <div className="news_seach_box_group">
                        <button className="news_button" type="button">
                            <img className="news_seach_box_img" src="./news_search.png" alt="search" />
                        </button>
                        <img className="news_seach_box_line" src="./news_line.png" alt="line" />
                        <input className="news_seach_box_input" type="search" placeholder="검색어를 입력해주세요" value={search}  
                        maxLength="10"  onChange={(e)=> setSearch(e.target.value)}/>
                        <img className="news_seach_box_line2" src="./news_line.png" alt="line" />
                        <div className="news_seach_box_result_group">
                            <div className="news_seach_box_result_txt">총</div>
                            <div className="news_seach_box_result_txt_number">{totalCount}</div>
                            <div className="news_seach_box_result_txt">건</div>
                        </div>
                    </div>
                    {_.chunk(searchedItems,2).map((items,groupIdx)=>{
                        const [firstItem,secondItem] = items; 
                        return  <div className="news_box_group" key={groupIdx}>
                            <div className="news_box">
                                <a href={firstItem.a} 
                                target={firstItem.a.startsWith('http') ? "_blank" : undefined}
                                rel={firstItem.a.startsWith('http') ? "noopener noreferrer" : undefined}
                                >
                                    <img className="news_box_img" src={firstItem.src} alt="news"/>
                                </a>
                                <div className="news_box_txt_group">
                                <a href={firstItem.a}
                                target={firstItem.a.startsWith('http') ? "_blank" : undefined}
                                rel={firstItem.a.startsWith('http') ? "noopener noreferrer" : undefined}
                                >
                                    <p className="news_box_txt_top">{firstItem.title}</p>
                                </a>
                                    <p className="news_box_txt_bottom">{firstItem.date}</p>
                                </div>
                            </div>
                            {secondItem && <div className="news_box2" >
                                <a href={secondItem.a} 
                                target={secondItem.a.startsWith('http') ? "_blank" : undefined}
                                rel={secondItem.a.startsWith('http') ? "noopener noreferrer" : undefined}
                                >
                                    <img className="news_box_img" src={secondItem.src} alt="news"/>
                                </a>
                                <div className="news_box_txt_group">
                                    <a href={secondItem.a} 
                                    target={secondItem.a.startsWith('http') ? "_blank" : undefined}
                                    rel={secondItem.a.startsWith('http') ? "noopener noreferrer" : undefined}
                                    >
                                        <p className="news_box_txt_top">{secondItem.title}</p>
                                    </a>
                                    <p className="news_box_txt_bottom">{secondItem.date}</p>
                                </div>
                            </div>}
                        </div>
                    })}
                    <div className="news_bg" />
                </div>
                <Footer />
            </div>
            </>
            :
            <div style={{backgroundColor:"black"}}>
            <MobileHeader />
            <div className="mobile_news_overlap_group">
                <div className="mobile_news_title_group">
                    <div className="mobile_news_title">News</div>
                    <Newsline />
                </div>
                <div className="mobile_news_seach_box_group">
                    <button className="mobile_news_button" type="button">
                        <img className="mobile_news_seach_box_img" src="./news_search.png" alt="search" />
                    </button>
                    <img className="mobile_news_seach_box_line" src="./news_line.png" alt="line" />
                    <input className="mobile_news_seach_box_input" type="search" placeholder="검색어를 입력해주세요" value={search}  
                    maxLength="10"  onChange={(e)=> setSearch(e.target.value)}/>
                    <img className="mobile_news_seach_box_line2" src="./news_line.png" alt="line" />
                   
                </div>
                <div className="mobile_news_seach_box_result_group">
                        <div className="mobile_news_seach_box_result_txt">총</div>
                        <div className="mobile_news_seach_box_result_txt_number">{totalCount}</div>
                        <div className="mobile_news_seach_box_result_txt">건</div>
                </div>
                {_.chunk(searchedItems,1).map((items,groupIdx)=>{
                    const [firstItem] = items; 
                    return  <div className="mobile_news_box_group" key={groupIdx}>
                        <div className="mobile_news_box">
                            <a href={firstItem.a} 
                            target={firstItem.a.startsWith('http') ? "_blank" : undefined}
                            rel={firstItem.a.startsWith('http') ? "noopener noreferrer" : undefined}
                            >
                                <img className="mobile_news_box_img" src={firstItem.src} alt="news"/>
                            </a>
                            <div className="mobile_news_box_txt_group">
                            <a href={firstItem.a}
                            target={firstItem.a.startsWith('http') ? "_blank" : undefined}
                            rel={firstItem.a.startsWith('http') ? "noopener noreferrer" : undefined}
                            >
                                <p className="mobile_news_box_txt_top">{firstItem.title}</p>
                            </a>
                                <p className="mobile_news_box_txt_bottom">{firstItem.date}</p>
                            </div>
                        </div>
                    </div>
                })}
            </div>
            <div className="mobile_news_bg" />
            <MobileFooter />
        </div>
        }
    </div>
    );
};

export default  News;

