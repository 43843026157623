import './mobilebox3.css';
import Box3Arrow from "../icon/box3_arrow";
import Box3MiniArrow from "../icon/box3_miniarrow"

export const MobileBox3 = () => {
    return (
      <div className="mobile_box3">
        <div className="mobile_box3_overlap_group">
          <div className="mobile_box3_bg"/>
          <img className="mobile_box3_logo" src="/mobile_box3_logo.svg" alt="logo" />
          <Box3Arrow className="mobile_box3_arrow"/>
          <p className="mobile_box3_overlap_txt">On-clever, On-dev.</p>
        </div>
        <div className="mobile_box3_overlap_group2">
            <p className="mobile_box3_overlap2_txt">We are</p>
            <Box3MiniArrow />
            <p className="mobile_box3_overlap2_txt2">working day</p>
        </div>
        <p className="mobile_box3_overlap2_txt3">and night to create</p>
      </div>
    );
  };
  
  export default MobileBox3;