import './partnersbox.css'

export const PartnersBox = ({
    img,
    alt,
    name,
    backgroundcolor
}) => {

    return(
        <div className="mobile_partnerbox_logo_frame">
            <img className="mobile_partnerbox_logo_img" src={img} alt={alt} style={{backgroundColor:backgroundcolor}} />
            <div className="mobile_partnerbox_logo_txt">{name}</div>
        </div>
    );
};

export default PartnersBox;