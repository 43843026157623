import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Main from './main/Main.js';
import Company from './company/Company.js';
import Technology from './technology/Technology.js';
import Partners from './Partners/Partners.js';
import News from './News/News.js';
import Contact from './Contact/Contact.js';
import { useEffect } from 'react';

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);  // location이 변경될 때마다 효과 실행

  return null;  // 이 컴포넌트는 UI를 렌더링하지 않음
}

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
      
        <Route path="/" element={ <Main />} />
        <Route path="/company" element={ <Company />} />
        <Route path="/technology" element={ <Technology />} />
        <Route path="/partners" element={ <Partners />} />
        <Route path="/news" element={ <News />} />
        <Route path="/contact" element={ <Contact />} />
      </Routes>

    </div>
  );
}

export default App;


