import "./mobileheader.css";
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import HeaderIcon from "../icon/header_icon"
import HeaderHambuger from "../icon/header_hambuger"
import MobileModal from "../main/MobileModal";

export const MobileHeader = () => {

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);

  const [navBackground, setNavBackground] = useState('transparent');

    const handleScroll = () => {
        const show = window.scrollY > 50;
        if (show) {
            setNavBackground('rgba(0, 0, 0, 0.5)');
        } else {
            setNavBackground('transparent');
        }
    };

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);

  return (
    <div className="mobile_header" style={{ backgroundColor: navBackground }}>
      <Link to='/'>
        <HeaderIcon />
      </Link>
      <div className="mobile_navbar">
        <Link to='/company' className="mobile_header_text_wrapper">Company</Link>
        <Link to='/technology' className="mobile_header_text_wrapper">Technology</Link>
        <Link to='/partners' className="mobile_header_text_wrapper">Partners</Link>
        <Link to='/news' className="mobile_header_text_wrapper">News</Link>
        <Link to='/contact' className="mobile_header_text_wrapper">Contact</Link>
      </div>
      <HeaderHambuger onClick={toggleModal} className="mobile_menu-icon" />
      <MobileModal isOpen={modalOpen} close={toggleModal}/>
    </div>
  );
};

export default MobileHeader;