import './mobileecosystem.css';
import MobileBox4 from './Mobilebox4.js'

export const MobileEcosystem = () => {  
  return (
      <div className="mobile_box4">
        <div className="mobile_box4_overlap_group">
          
          <div className="mobile_box4_txt_top_group">
            <div className="mobile_box4_txt_top">transparent financial ecosystem</div>
            <img className="mobile_box4_txt_ovj" src="/mobile_box4_ovj.png" alt="ovj" />
          </div>
          <p className="mobile_box4_txt_bottom">
            일반 금융 시장에서 가상자산의 중요성이 커지면서,
            <br/>
            투명성과 공정성을 유지하기 위해 
            <br/>
            금융 기술 분야에서는 관리 및 규제 시스템을
            <br/> 
            개발하는 것이 중요해졌습니다.
            <br/>
            온클레브는 AML(자금세탁방지) 시스템을 제공하여
            <br/> 
            국내 금융 기관이 해외 시장에 진출하는 데
            <br/>
            도움을 주고자 합니다.
          </p>

          <div className="mobile_box4_txt_hover_group">
              <MobileBox4
                title={"Investigation"} 
                afterImage={"./mobile_img/box4_hover_img1_after.png"} 
                beforeImage={"./mobile_img/box4_hover_img1.png"} 
                description={
                  <>
                  가상자산 범죄 자금 세탁 추적 등
                  <br/>
                  금융범죄 수사에 필요한 
                  <br/>
                  범죄 자금흐름 추적을 위한
                  <br/> 
                  AI 기술 개발
                  </>
                  }
                  isBar={true}
              />
              <MobileBox4
                title={"Securities"} 
                afterImage={"./mobile_img/box4_hover_img2_after.png"} 
                beforeImage={"./mobile_img/box4_hover_img2.png"} 
                description={
                  <>
                  AI 패턴분석을 통하여 유가증권의 
                  <br/>
                  유의종목, 자전거래 등을 
                  <br/>
                  사전에 탐지할 수 있는 
                  <br/>
                  이상거래 모니터링 기술 개발
                  </>
                  } 
                  isBar={true}

              />
              <MobileBox4
                title={"Transactions"} 
                afterImage={"./mobile_img/box4_hover_img3_after.png"} 
                beforeImage={"./mobile_img/box4_hover_img3.png"} 
                description={
                  <>
                  가상자산 범죄 자금 세탁 추적 등
                  <br/>
                  금융범죄 수사에 필요한 
                  <br/>
                  범죄 자금흐름 추적을 위한
                  <br/>
                  AI 기술 개발
                  </>
                  } 
                  isBar={true}

              />
              <MobileBox4
                title={"Virtual Assests FDS"} 
                afterImage={"./mobile_img/box4_hover_img4_after.png"} 
                beforeImage={"./mobile_img/box4_hover_img4.png"} 
                description={
                  <>
                  가상자산 거래소의 내부 거래에
                  <br/>
                  있어 자전 거래, 봇 거래 등 
                  <br/>
                  이상거래를 탐지하는 솔루션
                  </>
                  } 
                  isBar={true}

              />
              <MobileBox4
                title={"Wallet"} 
                afterImage={"./mobile_img/box4_hover_img5_after.png"} 
                beforeImage={"./mobile_img/box4_hover_img5.png"} 
                description={
                  <>
                  AML 기능 탑재 코인 지갑으로써
                  <br/>
                  가상자산 거래소의 입출금에 있어
                  <br/>
                  자금 출처 증빙 및 범죄 자금 관련
                  <br/>
                  소명에 유리한 지갑
                  </>
                  } 
                  isBar={false}
              />
          </div>
          <div className="mobile_box4_bg"/>
        </div>
      </div>
    );
  };
  
  export default MobileEcosystem;