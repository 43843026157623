export const NewsLine = () => {

    return(
        <svg width="247" height="26" viewBox="0 0 247 26" fill="none" xmlns="http://www.w3.org/2000/svg" style={{paddingTop: "50px"}}>
        <path d="M172.947 10C166.838 10 161.867 13.3636 161.867 17.502C161.867 21.3913 157.189 24.5573 151.442 24.5573H0.5V25H151.442C157.551 25 162.527 21.6364 162.527 17.498C162.527 13.6087 167.205 10.4427 172.953 10.4427H245V10L172.947 10Z" stroke="url(#paint0_linear_361_771)" strokeMiterlimit="10"/>
        <path d="M238 19.7012L246 10.2012L238 0.701172" stroke="#DDDDDD" strokeMiterlimit="10"/>
        <defs>
            <linearGradient id="paint0_linear_361_771" x1="9.5" y1="17.502" x2="179.169" y2="17.502" gradientUnits="userSpaceOnUse">
            <stop stopColor="#DDDDDD" stopOpacity="0"/>
            <stop offset="1" stopColor="#DDDDDD"/>
            </linearGradient>
        </defs>
        </svg>
    );
};

export default NewsLine;