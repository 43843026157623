export const HeaderIcon = () => {

    return(
        <svg width="83" height="21" viewBox="0 0 83 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_52_76)">
            <path d="M0.889784 10.2438C0.916874 10.2144 0.943963 10.1829 0.968969 10.1514C0.968969 10.1514 0.973136 10.1472 0.973136 10.1451C1.02732 10.0758 1.07316 10.0023 1.10858 9.9267C1.17943 9.7734 1.23361 9.618 1.27112 9.4647C1.30655 9.3114 1.32947 9.1791 1.3378 9.0699C1.34614 8.9607 1.3503 8.8788 1.3503 8.8242V0.5439H2.83606V0H1.43157C1.28779 0 1.14609 0.0546 1.01064 0.1743C0.929377 0.2457 0.875198 0.3507 0.841857 0.4893C0.833522 0.5229 0.829354 0.5607 0.823103 0.6006C0.814767 0.6678 0.8106 0.7392 0.8106 0.8169V8.7738C0.8106 8.8347 0.806432 8.8956 0.802265 8.9565C0.802265 8.9733 0.798097 8.9901 0.796013 9.009C0.789762 9.0594 0.78351 9.1098 0.773091 9.1602C0.771007 9.1749 0.76684 9.1896 0.764756 9.2043C0.741834 9.3135 0.708493 9.4227 0.668901 9.5319C0.660566 9.5508 0.652231 9.5697 0.643895 9.5865C0.637644 9.5991 0.633476 9.6096 0.627225 9.6222C0.577214 9.7167 0.512616 9.8049 0.433431 9.8847C0.418845 9.8994 0.402174 9.9099 0.387588 9.9225C0.2959 9.9834 0.168788 10.0254 0.0416761 10.0464C0.0229218 10.0464 0.0229218 10.0485 0.0020838 10.0485H0V10.5924V10.5987C0.0145866 10.5987 0.0270895 10.6008 0.0395923 10.6029C0.179207 10.6239 0.297984 10.6659 0.395923 10.7331C0.408426 10.7436 0.420928 10.752 0.431347 10.7625C0.533454 10.8654 0.610555 10.983 0.664733 11.1111C0.70641 11.2266 0.741834 11.34 0.764756 11.4555C0.764756 11.4639 0.768924 11.4744 0.771007 11.4828C0.781426 11.5374 0.789762 11.592 0.796013 11.6487C0.796013 11.6634 0.800181 11.6781 0.800181 11.6928C0.806432 11.7537 0.808516 11.8167 0.808516 11.8776V20.1852C0.808516 20.4939 0.875198 20.706 1.01064 20.8257C1.14609 20.9433 1.28571 21.0021 1.42949 21.0021H2.83397V20.4582H1.35239V11.8755C1.35239 11.8188 1.34822 11.6886 1.33989 11.5815C1.33155 11.4723 1.30863 11.34 1.2732 11.1867C1.23778 11.0313 1.1836 10.878 1.11067 10.7247C1.04399 10.5798 0.943963 10.4475 0.812684 10.3278C0.831438 10.311 0.848108 10.2921 0.866862 10.2732C0.875198 10.2648 0.885617 10.2564 0.891868 10.248L0.889784 10.2438Z" fill="white"/>
            <path d="M82.1103 10.2438C82.0832 10.2144 82.0562 10.1829 82.0311 10.1514C82.0311 10.1514 82.027 10.1472 82.027 10.1451C81.9728 10.0758 81.927 10.0023 81.8915 9.9267C81.8207 9.7734 81.7665 9.618 81.729 9.4647C81.6936 9.3114 81.6707 9.1791 81.6623 9.0699C81.654 8.9607 81.6498 8.8788 81.6498 8.8242V0.5439H80.1641V0H81.5685C81.7123 0 81.854 0.0546 81.9895 0.1743C82.0707 0.2457 82.1249 0.3507 82.1583 0.4893C82.1666 0.5229 82.1708 0.5607 82.177 0.6006C82.1853 0.6678 82.1895 0.7392 82.1895 0.8169V8.7738C82.1895 8.8347 82.1937 8.8956 82.1979 8.9565C82.1979 8.9733 82.202 8.9901 82.2041 9.009C82.2104 9.0594 82.2166 9.1098 82.227 9.1602C82.2291 9.1749 82.2333 9.1896 82.2354 9.2043C82.2583 9.3135 82.2916 9.4227 82.3312 9.5319C82.3396 9.5508 82.3479 9.5697 82.3562 9.5865C82.3625 9.5991 82.3666 9.6096 82.3729 9.6222C82.4229 9.7167 82.4875 9.8049 82.5667 9.8847C82.5813 9.8994 82.5979 9.9099 82.6125 9.9225C82.7042 9.9834 82.8313 10.0254 82.9584 10.0464C82.9772 10.0464 82.9772 10.0485 82.998 10.0485V10.5924V10.5966C82.9834 10.5966 82.9709 10.5987 82.9584 10.6008C82.8188 10.6218 82.7 10.6638 82.6021 10.731C82.5896 10.7415 82.5771 10.7499 82.5667 10.7604C82.4646 10.8633 82.3875 10.9809 82.3333 11.109C82.2916 11.2245 82.2562 11.3379 82.2333 11.4534C82.2333 11.4618 82.2291 11.4723 82.227 11.4807C82.2166 11.5353 82.2083 11.5899 82.202 11.6466C82.202 11.6613 82.1979 11.676 82.1979 11.6907C82.1916 11.7516 82.1895 11.8146 82.1895 11.8755V20.1831C82.1895 20.4918 82.1228 20.7039 81.9874 20.8236C81.8519 20.9412 81.7123 21 81.5685 21H80.1641V20.4561H81.6498V11.8755C81.6498 11.8188 81.654 11.6886 81.6623 11.5815C81.6707 11.4723 81.6936 11.34 81.729 11.1867C81.7644 11.0313 81.8186 10.878 81.8915 10.7247C81.9582 10.5798 82.0582 10.4475 82.1895 10.3278C82.1708 10.311 82.1541 10.2921 82.1353 10.2732C82.127 10.2648 82.1166 10.2564 82.1103 10.248V10.2438Z" fill="white"/>
            <path d="M12.2692 20.429C11.3502 20.429 10.4854 20.2337 9.67481 19.8431C8.86422 19.4525 8.15572 18.9254 7.5535 18.2639C6.9492 17.6003 6.47201 16.8254 6.12193 15.935C5.76977 15.0446 5.59473 14.1101 5.59473 13.1294C5.59473 12.1487 5.76977 11.1911 6.12193 10.3112C6.47409 9.43129 6.95545 8.65429 7.56809 7.98229C8.18073 7.31029 8.89339 6.77899 9.70399 6.38839C10.5146 5.99779 11.371 5.80249 12.2712 5.80249C13.1714 5.80249 14.0279 5.99779 14.8385 6.38839C15.6491 6.77899 16.3555 7.31029 16.9598 7.98229C17.5641 8.65429 18.0455 9.43129 18.406 10.3112C18.7665 11.1932 18.9457 12.1319 18.9457 13.1294C18.9457 14.1269 18.7706 15.0446 18.4185 15.935C18.0663 16.8254 17.5891 17.6003 16.9869 18.2639C16.3826 18.9275 15.6762 19.4525 14.8656 19.8431C14.055 20.2337 13.1902 20.429 12.2712 20.429H12.2692ZM6.13443 13.184C6.13443 14.0912 6.29697 14.9543 6.61996 15.7712C6.94295 16.5881 7.38055 17.3021 7.93067 17.909C8.4808 18.518 9.12469 18.9989 9.86236 19.3517C10.6 19.7066 11.394 19.883 12.24 19.883C13.086 19.883 13.857 19.7024 14.6051 19.3391C15.3532 18.9758 16.0096 18.4865 16.5785 17.8691C17.1453 17.2517 17.5912 16.5335 17.9163 15.7166C18.2413 14.8997 18.4018 14.0282 18.4018 13.1021C18.4018 12.176 18.2393 11.3318 17.9163 10.5149C17.5912 9.69799 17.1494 8.97979 16.591 8.36239C16.0325 7.74499 15.3782 7.25569 14.6322 6.89239C13.8841 6.52909 13.0964 6.34849 12.2671 6.34849C11.4377 6.34849 10.65 6.53119 9.90195 6.89239C9.15386 7.25569 8.50163 7.75129 7.94317 8.37709C7.38471 9.00289 6.94295 9.72949 6.61787 10.5569C6.2928 11.3843 6.13235 12.26 6.13235 13.1861L6.13443 13.184Z" fill="white"/>
            <path d="M31.7298 20.4279H31.1901V12.555C31.1901 10.4844 30.9067 8.97867 30.3378 8.03367C29.771 7.08867 28.8562 6.61827 27.5935 6.61827C26.9079 6.61827 26.2369 6.74007 25.5805 6.98577C24.922 7.23147 24.3135 7.57167 23.7572 8.00637C23.1987 8.44317 22.7111 8.95977 22.2985 9.55827C21.8838 10.1568 21.5775 10.8015 21.3795 11.4924V20.4258H20.8398V6.29067H21.3795V9.94047C21.6671 9.37767 22.038 8.85477 22.4881 8.37387C22.9382 7.89297 23.4342 7.48347 23.9739 7.14747C24.5136 6.81147 25.0908 6.54897 25.7034 6.35787C26.3161 6.16677 26.9371 6.07227 27.5684 6.07227C29.0104 6.07227 30.0628 6.59517 30.7317 7.63887C31.3985 8.68257 31.7319 10.3227 31.7319 12.555V20.4258L31.7298 20.4279Z" fill="white"/>
            <path d="M33.9993 13.0202C33.9993 12.0038 34.1702 11.0546 34.5119 10.1747C34.8536 9.29479 35.3308 8.53039 35.9435 7.88569C36.5561 7.24099 37.2729 6.73279 38.0919 6.36109C38.9108 5.98939 39.7985 5.80249 40.755 5.80249C41.9448 5.80249 43.0034 6.07969 43.9307 6.63409C44.858 7.18849 45.5561 7.96339 46.0249 8.96299L45.4852 9.15409C45.0164 8.28259 44.3537 7.59799 43.4993 7.09819C42.6429 6.59839 41.7031 6.34849 40.6758 6.34849C39.811 6.34849 39.0088 6.52069 38.2711 6.86509C37.5313 7.20949 36.8833 7.68199 36.3248 8.28049C35.7664 8.87899 35.3288 9.58879 35.0141 10.4057C34.6994 11.2226 34.5411 12.0941 34.5411 13.0202C34.5411 13.9463 34.7036 14.8262 35.0266 15.662C35.3517 16.4978 35.7976 17.2286 36.3644 17.8544C36.9312 18.4802 37.5855 18.9758 38.3232 19.3391C39.0608 19.7024 39.8548 19.883 40.7008 19.883C41.2405 19.883 41.7865 19.8011 42.3366 19.6373C42.8867 19.4735 43.391 19.2551 43.8494 18.9842C44.3099 18.7112 44.7017 18.3941 45.0247 18.0308C45.3498 17.6675 45.5644 17.2958 45.6728 16.9136L46.2125 17.0774C46.0499 17.5499 45.7936 17.9909 45.4415 18.3983C45.0893 18.8078 44.6704 19.1606 44.1849 19.4609C43.6994 19.7612 43.1618 19.9964 42.5762 20.1686C41.9907 20.3408 41.3905 20.4269 40.7779 20.4269C39.8589 20.4269 38.9837 20.2316 38.1565 19.841C37.3271 19.4504 36.6061 18.9191 35.9935 18.2471C35.3808 17.5751 34.8932 16.7897 34.5348 15.8909C34.1743 14.9921 33.9951 14.0345 33.9951 13.0181L33.9993 13.0202Z" fill="white"/>
            <path d="M48.1064 0.408203H48.6461V18.1931C48.6461 18.7202 48.8087 19.1381 49.1317 19.4468C49.4568 19.7555 49.8881 19.9109 50.4299 19.9109C50.6278 19.9109 50.8675 19.8878 51.1467 19.8437C51.4259 19.7975 51.6823 19.7387 51.9177 19.6673L52.1073 20.1041C51.8552 20.1944 51.5447 20.2721 51.1759 20.3351C50.8071 20.3981 50.4966 20.4296 50.2444 20.4296C49.613 20.4296 49.1004 20.2259 48.7045 19.8164C48.3086 19.4069 48.1106 18.8672 48.1106 18.1952V0.408203H48.1064Z" fill="white"/>
            <path d="M58.4862 20.4293C57.5672 20.4293 56.6983 20.234 55.8772 19.8434C55.0562 19.4528 54.3415 18.9173 53.7288 18.2369C53.1162 17.5565 52.6286 16.7669 52.2702 15.8681C51.9097 14.9693 51.7305 14.0117 51.7305 12.9953C51.7305 11.9789 51.9013 11.0612 52.2431 10.1897C52.5848 9.31823 53.0578 8.55593 53.6622 7.90283C54.2665 7.24973 54.9687 6.73733 55.771 6.36353C56.5732 5.99183 57.4338 5.80493 58.3528 5.80493C59.2718 5.80493 60.1678 5.99603 60.9888 6.37613C61.8077 6.75833 62.5204 7.27493 63.1247 7.92803C63.729 8.58113 64.2104 9.35393 64.5709 10.2422C64.9314 11.1326 65.1106 12.086 65.1106 13.1024V13.4027H52.2723C52.3431 14.3099 52.5557 15.1604 52.9078 15.95C53.26 16.7396 53.7143 17.4263 54.2727 18.0059C54.8312 18.5876 55.4751 19.0454 56.2044 19.3814C56.9337 19.7174 57.7047 19.8854 58.5153 19.8854C59.055 19.8854 59.5822 19.814 60.0969 19.667C60.6096 19.5221 61.093 19.3184 61.5431 19.0538C61.9932 18.7913 62.3891 18.4784 62.733 18.1151C63.0747 17.7518 63.3456 17.3528 63.5436 16.916L64.0562 17.0525C63.8582 17.5418 63.5748 17.9912 63.2039 18.4007C62.8351 18.8102 62.4016 19.163 61.9057 19.4633C61.4097 19.7636 60.87 19.9988 60.2845 20.171C59.6989 20.3432 59.0988 20.4293 58.4882 20.4293H58.4862ZM64.5396 12.8567C64.4854 11.9138 64.2875 11.0402 63.9457 10.2422C63.604 9.44423 63.1581 8.75753 62.6079 8.18633C62.0578 7.61513 61.4222 7.16573 60.7033 6.83813C59.9823 6.51053 59.2072 6.34673 58.3799 6.34673C57.5526 6.34673 56.7754 6.51053 56.0564 6.83813C55.3355 7.16573 54.6999 7.61933 54.1518 8.20103C53.6017 8.78273 53.1662 9.47153 52.8411 10.2716C52.5161 11.0717 52.3285 11.9327 52.2743 12.8588H64.5438L64.5396 12.8567Z" fill="white"/>
            <path d="M71.3781 20.4281L65.2705 6.29297H65.8644L71.6219 19.6385L77.3795 6.29297H77.9734L71.8657 20.4281H71.3802H71.3781Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_52_76">
            <rect width="83" height="21" fill="white"/>
            </clipPath>
        </defs>
</svg>
    );
};

export default HeaderIcon;