import './box5.css';

export const Box5 = () => {
    return (
      <div className="box5">
        <div className="box5_overlap_group">
          <div className="box5_txt_group">
            <div className="box5_txt_top">지금 온클레브와 함께하세요</div>
            <p className="box5_txt_bottom">
              금융정보 분석을 위한 인공지능 시스템 구축부터
              <br />
              가장 안전한 가상자산 관리 시스템까지 온클레브가 제공합니다. 
            </p>
          </div>
          <div className="box5_bg"/>
          <button onClick={()=>window.location.href='http://localhost:3000/contact'} className="box5_btn">
            <div className="box5_btn_txt_group">
              <div className="box5_btn_txt">Contact</div>
            </div>
            <img className="box5_btn_bg" alt="bg" src="box5_btn_bg.png" />
          </button>
        </div>
      </div>
    );
  };
  
  export default Box5;