import './box4.css';
import React, { useState, useEffect, useRef } from 'react';

export const Box4 = ({
  title,
  description,
  beforeImage,
  afterImage,
  isBar
}) => {
  const componentRef = useRef(null);  // 컴포넌트 참조 생성
  const [isVisible,setIsVisible] = useState(false);

  useEffect(() => {
    if(!componentRef.current) return;

    const handleScroll = () => {
      
      const rect = componentRef.current.getBoundingClientRect();

      const max = (window.innerHeight/2);
      const min = max - rect.height;
  
      setIsVisible(rect.top >= min && rect.top <= max);
    };

    // 스크롤 이벤트 리스너 등록
    window.addEventListener('scroll', handleScroll);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [componentRef]);

  return(
  <div className="box4_hover1 box4_hover" ref={componentRef}> 
      <div className="box4_img_hover" style={{backgroundImage: isVisible ? `url("${afterImage}")` : `url("${beforeImage}")`, height: isBar ? '258px':'48px'}}/>
      <div className="box4_txt_hover" >
        <CustomSVG isVisible={isVisible} />
        <div className="box4_txt_hover_title" style={{color: isVisible ? '#111111':'#dddddd' }}>{title}</div>
        <p className="box4_txt_hover_content" style={{color: isVisible ? '#777777':'#dddddd' }}>
          {description}
        </p>
      </div>
    </div>
  );
};

const CustomSVG = ({ isVisible }) => {
  return (
    <svg 
          className="box4_txt_hover_svg" 
          style={{ position: "absolute",
                  top : "-15px",
                  left: "290px",
                  zIndex: "900",
                  opacity: isVisible ?  1 : 0
          }}
          width="402" height="168" viewBox="0 0 402 168" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 26L27 1.5H400.5V134.5L369 166" stroke="#16D7CD" strokeWidth="3" strokeLinecap="round"/>
            <path d="M2.5 26L27 1.5H400.5V134.5L369 166" stroke="url(#paint0_linear_46_280)" strokeWidth="3" strokeLinecap="round"/>
            <path d="M326 166H2V65" stroke="#16D7CD" strokeWidth="3" strokeLinecap="round"/>
            <path d="M326 166H2V65" stroke="url(#paint1_linear_46_280)" strokeWidth="3" strokeLinecap="round"/>
            <defs>
            <linearGradient id="paint0_linear_46_280" x1="165.407" y1="1.49997" x2="246.121" y2="195.887" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0087CD" stopOpacity="0"/>
            <stop offset="1" stopColor="#0087CD"/>
            </linearGradient>
            <linearGradient id="paint1_linear_46_280" x1="134.618" y1="65" x2="174.524" y2="192.43" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0087CD" stopOpacity="0"/>
            <stop offset="1" stopColor="#0087CD"/>
            </linearGradient>
            </defs>
          </svg>
  );
}
 
export default Box4;