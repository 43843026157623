import './box3.css';

export const Box3 = () => {
    return (
      <div className="box3">
        <div className="box3_overlap_group">
          <div className="box3_bg"/>
          <img className="box3_logo" src="/logo.png" alt="logo" />
          <img className="box3_arrow" src="/box3_arrow_ovj.png" alt="arrow" />
          <p className="box3_overlap_txt">On-clever, On-dev.</p>
        </div>
        <div className="box3_overlap_group2">
            <p className="box3_overlap2_txt">We are</p>
            <img className="box3_vector" src="/box3_vector.png" alt="vector" />
            <p className="box3_overlap2_txt2">working day</p>
        </div>
        <p className="box3_overlap2_txt3">and night to create</p>
      </div>
    );
  };
  
  export default Box3;