export const HeaderHambuger = ({ onClick }) => {

    return(
        <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" style={{cursor : "pointer"}} onClick={onClick}>
            <path d="M3.75 6.75H26.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.75 15.5H26.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.75 24.25H26.25" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default HeaderHambuger;