import "./contact.css";
import Header from '../Header.js';
import Footer from '../Footer.js';
import { useRef, useState,useEffect } from "react";
import contact from "./contact.module.css"
import MobileHeader from "../mobile/header/MobileHeader.js";
import MobileFooter from "../mobile/footer/MobileFooter.js";

export const Contact = () => {

  const [fileName, setFileName] = useState('버튼을 눌러 파일을 첨부 해주세요.');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const maxSize = 8 * 1024 * 1024;

    if (file && file.size > maxSize) {
        alert('파일 크기가 8MB를 초과할 수 없습니다.');
        setFileName('파일이 선택되지 않았습니다.');
      } else {
        setFileName(`선택된 파일: ${file.name}`);
      }
  };

    const fileRef = useRef(null);
    
    const [buttons, setButtons] = useState([
        { label: '유가증권 관련', active: false },
        { label: '보험사기 탐지', active: false },
        { label: '대포통장 탐지', active: false },
        { label: '가상자산 추적', active: false },
        { label: '금융범죄 수사', active: false },
        { label: '기타 이상거래', active: false },
        { label: '금융 리스트 관리', active: false },
        { label: 'ETC', active: false }
      ]);

    const [showImage, setShowImage] = useState(false);

    const handleCheckboxChange = () => {
        setShowImage(!showImage);
    };

    const handleClick = (index) => {
    const newButtons = buttons.map((button, idx) => {
        if (idx === index) {
        return { ...button, active: !button.active };
        }
        return button;
    });
    setButtons(newButtons);
    };

    const isSubmitEnabled = () => {
        if(buttons.some(buttons => buttons.active) && showImage)
            return true;
        return false;
    };

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    
    useEffect(() => {
    function handleResize() {
        setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
        });
    }
    
    window.addEventListener('resize', handleResize);
    // 컴포넌트가 언마운트될 때 이벤트 리스너를 정리
    return () => window.removeEventListener('resize', handleResize);
    }, [setWindowSize]); // 빈 의존성 배열은 마운트될 때만 이펙트를 실행하라는 의미
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
    
        buttons.filter(button => button.active).forEach((button, index) => {
          formData.append(`inquiries[]`, button.label);
        });
        
        for (const [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }

        if (isSubmitEnabled()) {
            try {
                const response = await fetch('/send', {
                           method: 'POST',
                           body: formData,
                });

                const responseData = await response.json(); // JSON 응답을 파싱합니다.
                console.log('서버 응답 데이터:', responseData);
                
                if (responseData.success) {
                    alert('파일이 성공적으로 업로드되었습니다!');
                    } else {
                    alert('파일 업로드에 실패했습니다.'+ responseData.error);
                }
            } 
            catch (error) {
                alert('파일 업로드 중 오류가 발생했습니다.');
            }
            } else {
                alert('모든 필드를 채우고 개인정보 처리방침에 동의해주세요.');
            }
      };

      
    return(
        <div>
        {
            windowSize.width >= 720 ? 
            <>
            <div className="conatact">
                <Header />
                <div className="contact_overlap_group">
                    <div className="contact_title">Contact</div>
                    <div className="contact_txt_group_total">
                        <div className="contact_txt_group">
                            <p className="contact_txt">We are</p>
                            <img className="conatact_vector" src="/contact_arrow-right.png" alt="vector" />
                            <p className="contact_txt">working day</p>
                        </div>
                        <div className="contact_txt_group2">
                        <p className="contact_txt2">and night&nbsp;</p>
                        <p className="contact_txt2_gray">to create</p>
                        </div>
                        <div className="contact_txt_group3">
                            <div className="contact_txt3">transparent financial ecosysyem</div>
                            <img className="conatact_txt_ovj" src="/box4_ovj.png" alt="ovj" />
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="contact_form_txt_group">
                            <div className="contact_form_txt">01</div>
                            <div className="contact_form_txt2">기본 정보</div>
                        </div>
                        <div className="contact_form">
                            <div className="contact_form_group">
                                <label className="contact_form_group_txt" htmlFor="email">이메일&nbsp;<span className="contact_form_group_txt2">*&nbsp;</span></label>
                                <input type="email"  name="email" required placeholder="example@onclev.com" autoComplete="off" />
                            </div>
                            <div className="contact_form_group">
                                <label className="contact_form_group_txt" htmlFor="contact">연락처&nbsp;<span className="contact_form_group_txt2">*&nbsp;</span></label>
                                <input type="tel"  name="contact" required placeholder="010-1234-5678" autoComplete="off"/>
                            </div>
                            <div className="contact_form_group">
                                <label className="contact_form_group_txt" htmlFor="company">회사명&nbsp;<span className="contact_form_group_txt2">*&nbsp;</span></label>
                                <input type="text"  name="company" required placeholder="회사명을 입력해주세요" autoComplete="off" />
                            </div>
                            <div className="contact_form_group">
                                <label className="contact_form_group_txt" htmlFor="name">담당자 성함&nbsp;<span className="contact_form_group_txt2">*&nbsp;</span></label>
                                <input type="text"  name="name" required placeholder="담당자 성함을 입력해주세요" autoComplete="off" />
                            </div>
                        </div>
                        <div className="contact_form_txt_group">
                            <div className="contact_form_txt">02</div>
                            <div className="contact_form_txt2">문의 항목</div>
                            <div className="contact_form_txt3">중복 선택 가능</div>
                        </div>
                        <div className="contact_form_button_group_total">
                            <div className="contact_form_button_group">
                                {buttons.map((button, index) => (
                                    <button
                                    key={index}
                                    type="button"
                                    className={`${button.active ? contact.contact_form_button_active : contact.contact_form_button}`}
                                    onClick={() => handleClick(index)} 
                                    >
                                    <span className={`${button.active ? contact.contact_form_button_txt_active : contact.contact_form_button_txt}`}>{button.label}</span>
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className="contact_form_txt_group">
                            <div className="contact_form_txt">03</div>
                            <div className="contact_form_txt2">문의 내용</div>
                        </div>
                        <div className="contact_form">
                            <textarea name="content" className="contact_multi_line_input" rows="4" cols="50" placeholder="문의 내용을 여기에 입력해주세요." maxLength={500}></textarea>
                            <div className="contact_form_group_file">
                            <label className="contact_form_group_txt">{!fileName.includes('선택된 파일') && (<span className="contact_form_group_txt2">+&nbsp;</span>)}{fileName}&nbsp;</label>
                                <input onChange={handleFileChange} type="file" id="file" name="file" ref={fileRef} style={{visibility:'hidden'}}/>
                                <label htmlFor="file">
                                <img className="contact_form_img"  src="contact_plus.png" alt="plus"/>
                                </label>
                            </div>
                        </div>

                        <div className="contact_form_checkbox_group">
                            <label>
                                <input
                                type="checkbox"
                                checked={showImage}
                                onChange={handleCheckboxChange}
                                style={{width:"20px", height:"20px"}}
                                />
                            </label>

                            {showImage && (<img className="contact_form_checkbox" src="contact_checkbox.png" alt="설명" />)}
                            <div className="contact_form_checkbox_txt">&nbsp;개인정보처리방침에 동의합니다.</div>
                        </div>

                        <button type="submit" className="contact_form_submit_btn" disabled={!isSubmitEnabled && showImage}>
                            <div className="contact_form_submit_btn_group">
                                <div className="contact_form_submit_btn_txt">SUBMIT</div>
                            </div>
                            <img className="contact_form_submit_btn_bg" alt="bg" src="contact_form_submit_btn_bg.png" />
                        </button>

                    </form>
                    <div className="contact_bg" />
                </div>
                <Footer />
            </div>
            </>
            :
            <div style={{backgroundColor:"black"}}>
            <MobileHeader />
            <div className="mobile_contact_overlap_group">
                <div className="mobile_contact_title">Contact</div>
                <div className="mobile_contact_txt_group_total">
                    <div className="mobile_contact_txt_group">
                        <p className="mobile_contact_txt">We are</p>
                        <img className="mobile_conatact_vector" src="/contact_arrow-right.png" alt="vector" />
                        <p className="mobile_contact_txt">working day</p>
                    </div>
                    <div className="mobile_contact_txt_group2">
                    <p className="mobile_contact_txt2">and night&nbsp;</p>
                    <p className="mobile_contact_txt2_gray">to create</p>
                    </div>
                    <div className="mobile_contact_txt_group3">
                        <div className="mobile_contact_txt3">transparent financial ecosysyem</div>
                        <img className="mobile_conatact_txt_ovj" src="/mobile_contact_ovj.png" alt="ovj" />
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mobile_contact_form_txt_group">
                        <div className="mobile_contact_form_txt">01</div>
                        <div className="mobile_contact_form_txt2">기본 정보</div>
                    </div>
                    <div className="mobile_contact_form">
                        <div className="mobile_contact_form_group">
                            <label className="mobile_contact_form_group_txt" htmlFor="email">이메일&nbsp;<span className="contact_form_group_txt2">*&nbsp;</span></label>
                            <input type="email"  name="email" required placeholder="example@onclev.com" autoComplete="off" />
                        </div>
                        <div className="mobile_contact_form_group">
                            <label className="mobile_contact_form_group_txt" htmlFor="contact">연락처&nbsp;<span className="contact_form_group_txt2">*&nbsp;</span></label>
                            <input type="tel"  name="contact" required placeholder="010-1234-5678" autoComplete="off" />
                        </div>
                        <div className="mobile_contact_form_group">
                            <label className="mobile_contact_form_group_txt" htmlFor="company">회사명&nbsp;<span className="contact_form_group_txt2">*&nbsp;</span></label>
                            <input type="text"  name="company" required placeholder="회사명을 입력해주세요" autoComplete="off" />
                        </div>
                        <div className="mobile_contact_form_group">
                            <label className="mobile_contact_form_group_txt" htmlFor="name">담당자 성함&nbsp;<span className="contact_form_group_txt2">*&nbsp;</span></label>
                            <input type="text"  name="name" required placeholder="담당자 성함을 입력해주세요" autoComplete="off" />
                        </div>
                    </div>
                    <div className="mobile_contact_form_txt_group">
                        <div className="mobile_contact_form_txt">02</div>
                        <div className="mobile_contact_form_txt2">문의 항목</div>
                        <div className="mobile_contact_form_txt3">중복 선택 가능</div>
                    </div>
                    <div className="mobile_contact_form_button_group_total">
                        <div className="mobile_contact_form_button_group">
                            {buttons.map((button, index) => (
                                <button
                                key={index}
                                type="button"
                                className={`${button.active ? contact.mobile_contact_form_button_active : contact.mobile_contact_form_button}`}
                                onClick={() => handleClick(index)} 
                                >
                                <span className={`${button.active ? contact.mobile_contact_form_button_txt_active : contact.mobile_contact_form_button_txt}`}>{button.label}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="mobile_contact_form_txt_group">
                        <div className="mobile_contact_form_txt">03</div>
                        <div className="mobile_contact_form_txt2">문의 내용</div>
                    </div>
                    <div className="mobile_contact_form">
                        <textarea name="content" className="mobile_contact_multi_line_input" rows="3" cols="30" placeholder="문의 내용을 여기에 입력해주세요." maxLength={500}></textarea>
                        <div className="mobile_contact_form_group_file">
                            <label className="mobile_contact_form_group_file_txt">{!fileName.includes('선택된 파일') && (<span className="mobile_contact_form_group_txt2">+&nbsp;</span>)}{fileName}&nbsp;</label>
                            <input onChange={handleFileChange} type="file" id="file" name="file" ref={fileRef} required style={{visibility:'hidden'}}/>
                            <label htmlFor="file">
                            <img className="mobile_contact_form_img"  src="contact_plus.png" alt="plus"/>
                            </label>
                        </div>
                    </div>

                    <div className="mobile_contact_form_checkbox_group">
                        <label>
                            <input
                            type="checkbox"
                            checked={showImage}
                            onChange={handleCheckboxChange}
                            style={{width:"20px", height:"20px"}}
                            />
                        </label>

                        {showImage && (<img className="mobile_contact_form_checkbox" src="contact_checkbox.png" alt="설명" />)}
                        <div className="mobile_contact_form_checkbox_txt">&nbsp;개인정보처리방침에 동의합니다.</div>
                    </div>
                    <div className="mobile_contact_form_submit_btn_group1">
                        <button type="submit" className="mobile_contact_form_submit_btn" disabled={!isSubmitEnabled}>
                            <div className="mobile_contact_form_submit_btn_group">
                                <div className="mobile_contact_form_submit_btn_txt">SUBMIT</div>
                            </div>
                            <img className="mobile_contact_form_submit_btn_bg" alt="bg" src="contact_form_submit_btn_bg.png" />
                        </button>
                    </div>
                </form>
            </div>
            <div className="mobile_contact_bg" />
            <MobileFooter />
        </div>
        }
    </div>
    );
};

export default Contact;