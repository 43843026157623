
export const CompanyLine = () => {

    return(
        <svg width="13rem" height="15" viewBox="0 0 200 15" fill="none" xmlns="http://www.w3.org/2000/svg" style={{paddingTop: "50px"}}>
            <path d="M323 1.18945H110.378C106.857 1.18945 104 4.32272 104 8.18945" stroke="url(#paint0_linear_52_258)" strokeMiterlimit="10"/>
            <path d="M0 14.1895H97.643C101.152 14.1895 104 11.5038 104 8.18945" stroke="#DDDDDD" strokeMiterlimit="10"/>
            <path d="M104 11C105.657 11 107 9.65685 107 8C107 6.34315 105.657 5 104 5C102.343 5 101 6.34315 101 8C101 9.65685 102.343 11 104 11Z" fill="#DDDDDD"/>
            <defs>
                <linearGradient id="paint0_linear_52_258" x1="323" y1="4.57485" x2="103.791" y2="4.57485" gradientUnits="userSpaceOnUse">
                <stop stopColor="white" stopOpacity="0"/>
                <stop offset="1" stopColor="#DDDDDD"/>
                </linearGradient>
            </defs>
        </svg>

    );
};

export default CompanyLine;