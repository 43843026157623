import "./partners.css";
import Header from '../Header.js';
import Footer from '../Footer.js';
import { useState, useEffect } from "react";
import MobileHeader from "../mobile/header/MobileHeader.js";
import MobileFooter from "../mobile/footer/MobileFooter.js";
import PartnersLine from "../mobile/icon/partners_line.js";
import Slider from 'react-slick';
import PartnersBox from "./PartnersBox.js";
import DPartnersBox from "./DPartnersbox.js"

export const Partners = () => {

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    
    useEffect(() => {
    function handleResize() {
        setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
        });
    }
    
    window.addEventListener('resize', handleResize);
    // 컴포넌트가 언마운트될 때 이벤트 리스너를 정리
    return () => window.removeEventListener('resize', handleResize);
    }, [setWindowSize]); // 빈 의존성 배열은 마운트될 때만 이펙트를 실행하라는 의미

    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows : false,
      };

    return(
        <div>
        {
            windowSize.width >= 720 ? 
            <>
            <div className="partners">
                <Header />
                <div className="partners_overlap_group">
                    <div className="partner_title">Partners</div>
                    <div className="partner_logo_total_group">
                        <div className="partner_logo_group">
                            <DPartnersBox img={"./Partners_img/NONGHYUP.svg"} alt={"농협금융지주"} name={"농협금융지주"} backgroundcolor={'#ffffff'} />
                            <DPartnersBox img={"./Partners_img/kakaobank.svg"} alt={"카카오뱅크"} name={"카카오뱅크"} backgroundcolor={'#ffffff'} />
                            <DPartnersBox img={"./Partners_img/KNPA.svg"} alt={"경찰청"} name={"경찰청"} backgroundcolor={'#ffffff'}/>
                            <DPartnersBox img={"./Partners_img/KOTEC.svg"} alt={"기술보증기금"} name={"기술보증기금"} backgroundcolor={'#ffffff'}/>
                            <DPartnersBox img={"./Partners_img/KFTC.svg"} alt={"금융결제원"} name={"금융결제원"} backgroundcolor={'#ffffff'}/>
                            <DPartnersBox img={"./Partners_img/KFC.svg"} alt={"한국핀테크지원센터"} name={"한국핀테크지원센터"} backgroundcolor={'#ffffff'}/>
                        </div>
                        <div className="partner_logo_group">
                            <DPartnersBox img={"./Partners_img/KORFIN.jpeg"} alt={"핀테크산업협회"} name={"핀테크산업협회"} backgroundcolor={'#ffffff'} />
                            <DPartnersBox img={"./Partners_img/SFL.svg"} alt={"서울핀테크랩"} name={"서울핀테크랩"} backgroundcolor={'#ffffff'}/> 
                            <DPartnersBox img={"./Partners_img/BT.png"} alt={"부산테크노파크"} name={"부산테크노파크"} backgroundcolor={'#ffffff'}/>
                            <DPartnersBox img={"./Partners_img/logo_busan.png"} alt={"부산창조경제혁신센터"} name={"부산창조경제혁신센터"} backgroundcolor={'#000000'} padding={'40px'} />
                            <DPartnersBox img={"./Partners_img/SeoulTech.svg"} alt={"서울과학기술대학"} name={"서울과학기술대학"} backgroundcolor={'#ffffff'} />
                        </div>
                    </div>
                    <div className="partners_bg" />
                </div>
                <Footer />
            </div>
            </>
            :
            <div style={{backgroundColor:"black"}}>
            <MobileHeader />
            <div className="mobile_partners_overlap_group">
                <div className="mobile_partners_title_group">
                    <div className="mobile_partner_title">Partners</div>
                    <PartnersLine />
                </div>
                
                <div className="mobile_partner_logo_total_group">
                    
                    <div className="mobile_partner_logo_group" >
                        <Slider {...settings} style={{height:"150px", width: "100%"}}>
                        <PartnersBox img={"./Partners_img/NONGHYUP.svg"} alt={"농협금융지주"} name={"농협금융지주"} backgroundcolor={'#ffffff'} />
                        <PartnersBox img={"./Partners_img/kakaobank.svg"} alt={"카카오뱅크"} name={"카카오뱅크"} backgroundcolor={'#ffffff'} />
                        <PartnersBox img={"./Partners_img/KNPA.svg"} alt={"경찰청"} name={"경찰청"} backgroundcolor={'#ffffff'}/>
                        <PartnersBox img={"./Partners_img/KOTEC.svg"} alt={"기술보증기금"} name={"기술보증기금"} backgroundcolor={'#ffffff'}/>
                        <PartnersBox img={"./Partners_img/KFTC.svg"} alt={"금융결제원"} name={"금융결제원"} backgroundcolor={'#ffffff'}/>
                        <PartnersBox img={"./Partners_img/KFC.svg"} alt={"한국핀테크지원센터"} name={"한국핀테크지원센터"} backgroundcolor={'#ffffff'}/>
                        </Slider>
                    </div>
                    <Slider {...settings} style={{height:"150px", width: "100%"}}>
                        <PartnersBox img={"./Partners_img/KFIA.svg"} alt={"핀테크산업협회"} name={"핀테크산업협회"} backgroundcolor={'#000000'} />
                        <PartnersBox img={"./Partners_img/SFL.svg"} alt={"서울핀테크랩"} name={"서울핀테크랩"} backgroundcolor={'#ffffff'}/> 
                        <PartnersBox img={"./Partners_img/mobile_BT.png"} alt={"부산테크노파크"} name={"부산테크노파크"} backgroundcolor={'#ffffff'}/>
                        <PartnersBox img={"./Partners_img/logo_busan.png"} alt={"부산창조경제혁신센터"} name={"부산창조경제혁신센터"} backgroundcolor={'#000000'}/>
                        <PartnersBox img={"./Partners_img/SeoulTech.svg"} alt={"서울과학기술대학"} name={"서울과학기술대학"} backgroundcolor={'#ffffff'} />
                    </Slider>
                </div>
            </div>
            <div className="mobile_partners_bg" />
            <MobileFooter />
        </div>
        }
    </div>
    );
};

export default Partners;