import "./header.css";
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';


export const Header = () => {

  const [navBackground, setNavBackground] = useState('transparent');

    const handleScroll = () => {
        const show = window.scrollY > 50;
        if (show) {
            setNavBackground('rgba(0, 0, 0, 0.5)');
        } else {
            setNavBackground('transparent');
        }
    };

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);

  return (
    <div className="header" style={{ backgroundColor: navBackground }}>
      <Link to='/'>
        <img className="layer" alt="Layer" src="/Layer_1.png" />
      </Link>
      <div className="navbar">
        <Link to='/company' className="header_text_wrapper">Company</Link>
        <Link to='/technology' className="header_text_wrapper">Technology</Link>
        <Link to='/partners' className="header_text_wrapper">Partners</Link>
        <Link to='/news' className="header_text_wrapper">News</Link>
        <Link to='/contact' className="header_text_wrapper">Contact</Link>
      </div>
      <img className="menu-icon" src="/header_menu.png" alt="menu"/>
    </div>
  );
};

export default Header;