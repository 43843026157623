import "./txtbottomgroup.css";
import React, { useState, useEffect, useRef } from 'react';

export const TxtBottomGroup = ({
    year,
    month,
    month_content1,
    month_content2,
    month_content3,
    month2,
    month2_content1,
    month2_content2,
    month2_content3,
    padding_top
    }) => {

    const componentRef = useRef(null);  // 컴포넌트 참조 생성
    const [isVisible,setIsVisible] = useState(false);

    useEffect(() => {
        if(!componentRef.current) return;

        const handleScroll = () => {
        
        const rect = componentRef.current.getBoundingClientRect();

        const max = (window.innerHeight/2);
        const min = max - rect.height;
    
        setIsVisible(rect.top >= min && rect.top <= max);
        };

        // 스크롤 이벤트 리스너 등록
        window.addEventListener('scroll', handleScroll);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, [componentRef]);


    return (
                <div className="company_bottom_group" style={{paddingTop : padding_top}} ref={componentRef}>
                    <div className="company_txt_bottom_title " style={{color : isVisible ? "#ffffff" : "#777777"}}>{year}</div>
                    <svg className="company_txt_bottom_img" width="116" height="4" viewBox="0 0 116 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="company_txt_bottom_path" d="M0 2H116" stroke="#444444" strokeWidth="3" style={{stroke : isVisible ? "#ffffff" : "#444444"}}/>
                    </svg>
                    <div className="company_txt_bottom_number" style={{color : isVisible ? "#ffffff" : "#777777"}}>{month}</div>
                    <div className="company_txt_bottom_txt_group" style={{color : isVisible ? "#ffffff" : "#777777"}}>
                        <div>{month_content1}</div>
                        <div>{month_content2}</div>
                        <div>{month_content3}</div>
                    </div>
                    <div className="company_txt_bottom_number2" style={{color : isVisible ? "#ffffff" : "#777777"}}>{month2}</div>
                    <div className="company_txt_bottom_txt_group" style={{color : isVisible ? "#ffffff" : "#777777"}}>
                        <div>{month2_content1}</div>
                        <div>{month2_content2}</div>
                        <div>{month2_content3}</div>
                    </div>
                </div>
      );
  };

export default TxtBottomGroup;